import { AboutSection, Counter, ServiceCard, Testimonial, Title } from '../components/comps'
import './about.css'

export default function About(){
    const service = [{
        image:'./images/service_card_1.svg',
        title:'Blood Test',
        btn:'Book Now',
    }, {
        image:'./images/service_card_1.svg',
        title:'X-Ray',
        btn:'Book Now',
    }, {
        image:'./images/service_card_1.svg',
        title:'ECG',
        btn:'Book Now',
    }, ]
    return(
        <>
        <section className='aboutWrapper aboutLanding'>
            <h2 className='sectionTopic'>About Us</h2>
        </section>
        <AboutSection/>
        <div style={{height:"5rem"}}></div>
        <Counter/>
        <section className='homeServices aboutService'>
            <Title title={"Our service"}/>
            <h2 className='sectionTopic'>We Have 5+ Years Experience in Medical Health Services</h2>
            <article>
                    {service.map((card, key)=>(<ServiceCard props={card} key={key}/>))}
            </article>
        </section>
        <section className='aboutWrapper aboutBanner'>
            <h2 className='sectionTopic'>Innovative Testing for Informed Decisions.</h2>
            <p>Advanced testing methods to guide your health journey. We utilize the latest innovations in diagnostic testing to provide you with detailed and actionable insights that help guide your health decisions.</p>
            {/* <aside>
                <button className='themeBtn Btn'>Discover More</button>
                <button className='theme2Btn Btn'>See All Service</button>
            </aside> */}
        </section>
        <Testimonial/>
        </>
    )
}