import { useState } from 'react'
import './contact.css'
import { EnqueryForm } from '../components/comps'

export function ContactCard({props}){
    const color = {theme:"#04CE78", theme2:"#1F5FFF"}
    return(
        <div className='contactCard' style={{"--location_theme":props.color?color.theme:color.theme2}}>
            <h2 className='serviceCardTitle contactLocation'>{props.location}</h2>
            <p className='contactDetails sectionContent'>
                <span>
                    *
                </span>
                {props.address}
            </p>
            <a href={`mailto:${props.mail}`} className='sectionContent contactDetails'>
                <span>
                    *
                </span>
                {props.mail}
            </a>
            <a href={`tel:+91${props.phone}`} className='contactDetails sectionContent'>
                <span>
                    *
                </span>
                {props.phone}
            </a>
        </div>
    )
}
export default function Contact(){
    const branches = [{
        location:"Timiri, Ranipet",
        address:"671/5, Arani Main Road, Iyyangar Bakery Near, Thangam Scrap Opposite, Vellore, Timiri - 632512",
        mail:"tamilselvanrs@vrtshealthcareindia.com",
        phone:"04172-290218",
        color:true
    }, {
        location:"Arcot, Ranipet",
        address:"No. 2/1/61, Arani Road, Nel Arisi Mandapam Opposite, TMB Bank Near, Arcot - 632503",
        mail:"support@vrtshealthcareindia.com",
        phone:"04172-358097",
        color:false
    }]
    return(
        <>
            <section className='contactWrapper contactLanding'>
                <h2 className='sectionTopic'>Contact</h2>                
            </section>
            <section className='contactBranches'>
                {branches.map((loc, key)=>(<ContactCard props={loc} key={key}/>))}                
            </section>
            <EnqueryForm/>
        </>
    )
}