import "./comps.css"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

export function Title ({title}){
    return(
        <div className="title">
            <img src='./images/title_icon.svg' alt='?'/>
            <h5>{title}</h5>
        </div>
    )
}

export function Testimonial(){

    // To Choose Display
    const [display, setdisplay] = useState(true)
    const navSelect = () =>{
        if(window.innerWidth<768){
            setdisplay(false)
        }else{
            setdisplay(true)
        }
    }
    useEffect(() => {
    if (window.innerWidth<768){
        setdisplay(false)
    }else{
        setdisplay(true)
    }
    window.addEventListener('resize', navSelect);
    window.scrollTo(0, 0);
    return () => {
        window.scrollTo(0, 0);
        window.removeEventListener('resize', navSelect);
    };
    }, []);

    const testContent = [{
        rating:5,
        test:`“I recently had a blood test done at VRTS, and I couldn't be more impressed with their service. The advanced vacuum blood collection system made the process quick and comfortable, and the 7-step procedure ensured everything was handled with precision. The quality of their diagnostics is top-notch, and I received my e-receipt and reports almost immediately. Plus, their senior citizen discount was a pleasant surprise. Highly recommend VRTS for anyone needing reliable and affordable health care!”`,
        name:"Arjun",
        who:"Vellore"
    }, {
        rating:5,
        test:`“VRTS provides excellent quality in their health care services. From the moment I walked in, the staff was professional and attentive. The use of advanced technology in their blood collection and the thorough 7-step testing procedure gave me confidence in their results. I appreciated the convenience of the e-receipt and online reports, and the senior citizen discount was a great benefit. Overall, a fantastic experience, and I will definitely return for any future health needs.”`,
        name:"Nandhini",
        who:"Timiri"
    }, {
        rating:5,
        test:`“I had a great experience with VRTS. Their advanced blood collection technology and detailed testing procedures ensure accurate results. The quality of their service is impeccable, and receiving my reports via e-receipt was very convenient. The senior citizen discount they offer is also a wonderful gesture. VRTS makes it easy to access high-quality health care without breaking the bank. I highly recommend them to anyone looking for reliable diagnostics.”`,
        name:"Rajesh",
        who:"Arcot"
    }, {
        rating:4,
        test:`“VRTS provides outstanding service with a focus on excellence. From the advanced technology they use to the clear and timely e-reports, everything was handled perfectly. The senior citizen discount was an added bonus. I’m very satisfied and would highly recommend their services!”`,
        name:"Meera",
        who:"Vellore"
    }, ]


    const [translate, setTranslate] = useState(0)
    useEffect(()=>{
        const interval =setInterval(()=>{
            if(translate+(100/2)<=100){
                setTranslate(translate+(100/(testContent.length)))
            }else{
                setTranslate(0)
            }
        },5000);
        return () => clearInterval(interval);
    },[translate])

    return(
        <section className='homeTestimonail'>
            <Title title={"Testimonials"}/>
            <h2 className='sectionTopic'>What Our Patient Says?</h2>
            <main>
                <div className='slider' style={{width:`${display?100*testContent.length/2:100*testContent.length}%`, transform:`translateX(-${translate}%)`}}>
                    {testContent.map((content, key)=>(
                        <div className='slide' key={key}>
                            <figure>                                
                                {/* <img src='./images/rating1.svg' alt='*'/> */}
                                {Array.from({length:content.rating}).map((_,index)=>(
                                    <img src='./images/rating1.svg' alt='*' key={index}/>
                                ))}
                            </figure>
                            <p className='sectionContent testContent'> {content.test}</p>
                            <aside>
                                <span>
                                    <h3>{content.name}</h3>
                                    <h4>{content.who}</h4>
                                </span>
                                <img src='./images/quote_1.svg' alt='"'/>
                            </aside>
                        </div>
                    ))}                        
                </div>
            </main>
        </section>
    )
}

export function AboutSection(){
    return(
        <section className='homeAbout'>
            <figure>
                <img className='homeAboutImage' src='./images/about_1_3.png' alt='VRTS'/>
                {/* <img src='./images/about_3_2.jpg' alt='VRTS'/> */}
                <div className='topLeft'>
                    <img src='./images/about_3_2.jpg' alt='VRTS'/>
                </div>
                <div className='bottomRight'>
                    <aside>
                        <h3 className='subTitle'>Mr.Tamilselvan R S</h3>
                        <p>Managing Director and CEO</p>
                        {/* <p>+919344504082</p> */}
                    </aside>
                </div>
            </figure>
            <main>
                <Title
                title={"About Our Company"}/>
                <h2 className='sectionTopic'>Affordable Health Care Solutions</h2>
                {/* <p className='sectionContent homeAboutContent'>A brief statement outlining the purpose and mission of the clinic. This can include the commitment to patient care, community health, and any specifical goals for our values.</p> */}
                <p className='sectionContent homeAboutContent'>At VRTS Healthcare, we offer doorstep blood sample collection services, bringing the lab to your doorstep. No more waiting in long queues or travelling to the lab. Our skilled phlebotomists ensure a hassle-free and painless blood sample collection experience in the comfort of your home or office.</p>
                <ul>
                    <li>Online Booking</li>
                    <li>Easy Result</li>
                    <li>Doorstep Sample</li>
                    <li>Affordable Price</li>
                </ul>
                <aside>
                    <Link to='/about' className='Btn themeBtn'>More About Us</Link>
                </aside>
            </main>
            <div className='homeAboutBg'>
                <img src='./images/pattern_shape_1.png' alt=''/>
            </div>
            <div className='homeAboutMedicine'>
                <img src='./images/medicine_1.png' alt=''/>
            </div>
        </section>
    )
}

export function ServiceCard({props}){
    return(
        <div className='serviceCard'>
            <figure>
                {/* <img src='./images/service_card_1.svg' alt=''/> */}
                <img src={props.image} alt=''/>
            </figure>
            <h3 className='serviceCardTitle cardTitleText'>{props.title}</h3>
            <Link to={'/contact'} className='Btn theme2Btn'>{props.btn} </Link>
        </div>
    )
}

export function EnqueryForm(){

    const [submitCheck, setSubmitCheck] = useState(false)
    const [enq, setEnq] = useState({
        name:'',
        mail:'',
        phone:'',
        test:"",
        date:'',
        time:''
    }) 
    const inputChange = (e)=>{
        const {name, value} = e.target;
        setEnq((preData)=>({
            ...preData,
            [name]:value
        }))
    }
    const enqSubmit = async(e)=>{
        e.preventDefault()
        setSubmitCheck(true)

        const formData = new FormData(e.target);
        formData.append("access_key", process.env.REACT_APP_KEY);
        
        const response = await fetch(process.env.REACT_APP_LINK, {
            method: "POST",
            body: formData
        });
        
        const data = await response.json();
      
        if (data.success) {
            setSubmitCheck(false)
            setEnq({
                name:'',
                mail:'',
                phone:'',
                test:"",
                date:'',
                time:'' 
            })
            alert(`Our Team will Contact you soon...
Feel free to call us...`)
        } else {
            console.log("Error", data);
            setSubmitCheck(false)
        }
    }
    return(
        <section className='homeEnq'>
            <div className='timingContainer'>
                <div className='timing'>
                    <h2 className='enqtitle white'>Working Hours</h2>
                    {/* <p className='sectionContent white'>Variations of passages amt available are anything embarrassing.</p> */}
                    <span>
                        <h3>Monday - Tuesday:</h3>
                        <h3>6.50am - 7.50pm</h3>
                    </span>
                    <span>
                        <h3>Wednesday - Thursday:</h3>
                        <h3>6.50am - 7.50pm</h3>
                    </span>
                    <span>
                        <h3>Friday:</h3>
                        <h3>6.50am - 7.50pm</h3>
                    </span>
                    <span>
                        <h3>Saturday:</h3>
                        <h3>6.50am - 7.50pm</h3>
                    </span>
                    <span>
                        <h3>Sunday:</h3>
                        <h3>7.00am - 12.15pm</h3>
                    </span>
                </div>
                <div className='enqBG'></div>
                <div className='enqShape'></div>
            </div>
            <div className='formContainer'>
                <div className='formPic'>
                    <img src='./images/form_1_1.jpg' alt=''/>
                    <img src='./images/form_1_2.jpg' alt=''/>
                </div>
                <form onSubmit={enqSubmit}>
                    <h2 className='enqtitle title'>Make An Booking</h2>
                    <div className='inputContainer'>
                        <input
                        type='text'
                        name='name'
                        placeholder='Name*'
                        value={enq.name}
                        onChange={inputChange}
                        required/>
                    </div>
                    <div className='inputContainer'>
                        <input
                        type='mail'
                        name='mail'
                        placeholder='Email Address*'
                        value={enq.mail}
                        onChange={inputChange}
                        required/>
                    </div>
                    <div className='inputContainer'>
                        <input
                        type='tel'
                        name='phone'
                        placeholder='Mobile number*'
                        value={enq.phone}
                        onChange={inputChange}
                        required/>
                    </div>
                    <div className='inputContainer'>
                        <input
                        type='text'
                        name='test'
                        placeholder='Test*'
                        value={enq.test}
                        onChange={inputChange}
                        required/>
                    </div>
                    <div className='formRow'>
                        <div className='inputContainer'>
                            <input
                            type='date'
                            name='date'
                            placeholder='Date*'
                            value={enq.date}
                            onChange={inputChange}
                            required/>
                        </div>
                        <div className='inputContainer'>
                            <input
                            type='time'
                            name='time'
                            placeholder='Time*'
                            value={enq.time}
                            onChange={inputChange}
                            required/>
                        </div>
                    </div>
                    <div className='inputContainer'>
                        <button className='Btn themeBtn' disabled={submitCheck} value={"Submit"}>Book Now</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export function Counter(){
    return(
        <section className='homeCounter'>
                <div>
                    <h2>12K<span>+</span></h2>
                    <p>Patients</p>
                </div>
                <div>
                    <h2>7<span>+</span></h2>
                    <p>Collection Labs</p>
                </div>
                <div>
                    <h2>5<span>+</span></h2>
                    <p>Years Successful</p>
                </div>
                <div>
                    <h2>16<span>+</span></h2>
                    <p>Corporate Company Camp's</p>
                </div>
            </section>
    )
}
