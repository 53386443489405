import './nav.css'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function Nav(){
    const fullPath = useLocation()
    const path = fullPath.pathname.substring(1)
    console.log(path)
    // To Choose Display
    const [display, setdisplay] = useState(false)
    const navSelect = () =>{
        if(window.innerWidth<768){
            setdisplay(true)
        }else{
            setdisplay(false)
        }
    }
    useEffect(() => {
    if (window.innerWidth<768){
        setdisplay(true)
    }else{
        setdisplay(false)
    }
    window.addEventListener('resize', navSelect);
    window.scrollTo(0, 0);
    return () => {
        window.scrollTo(0, 0);
        window.removeEventListener('resize', navSelect);
    };
    }, []);

    const [showMenu, setShowMenu] = useState(false)
    return(
        <nav>
            <figure>
                <img src='./images/footerLogo.png' alt='VRTS'/>
            </figure>
            <ul className={display?"hide":"show"}>
                <li><Link to='/' className={path===''?'sectionContent webPath':' sectionContent'}>Home</Link></li>
                {/* <li><Link to='/service' className='sectionContent'>Service</Link></li> */}
                <li><Link to='/about' className={path==='about'?'sectionContent webPath':' sectionContent '}>About Us</Link></li>
                <li><Link to='/contact' className={path==='contact'?'sectionContent webPath':' sectionContent'}>Contact Us</Link></li>
            </ul>
            <a href='https://api.whatsapp.com/send/?phone=919344504082&text&type=phone_number&app_absent=0' className='menuContact'>
                <img src='./images/call.svg' alt=''/>
                <p>+91 9344504082</p>
            </a>
            <div className={display?'menuIcon show':'menuIcon hide'} onClick={()=>setShowMenu(!showMenu)}>
                <div className={showMenu?'bar bar1':'bar'}/>
                <div className={showMenu?'bar bar2':'bar'}/>
                <div className={showMenu?'bar bar3':'bar'}/>
            </div>
            <section className={showMenu?"mobMenuShow":" mobMenuHide"}>
                <ul>
                    <li><Link to='/' className={path===''?'sectionContent mobPath':'sectionContent'} onClick={()=>setShowMenu(!showMenu)}>Home</Link></li>
                    {/* <li><Link to='/service' className={path==='service'?'sectionContent':'mobPath'} onClick={()=>setShowMenu(!showMenu)}>Service</Link></li> */}
                    <li><Link to='/about' className={path==='about'?'sectionContent mobPath':'sectionContent '} onClick={()=>setShowMenu(!showMenu)}>About Us</Link></li>
                    <li><Link to='/contact' className={path==='contact'?'mobPath sectionContent':'sectionContent '} onClick={()=>setShowMenu(!showMenu)}>Contact Us</Link></li>
                </ul>
            </section>
        </nav>
    )
}