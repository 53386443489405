import { Link } from 'react-router-dom'
import './footer.css'

export default function Footer(){
    return(
        <section className='footer'>
            <div className='footerMain'>
                <figure>
                    <img src='./images/footerLogo.svg' alt=''/>
                </figure>
                <h3 className='subTitle'>Head Office Address</h3>
                <p className='sectionContent'># 671/5, Arani Main Road, Iyyangar Bakery Near, Thangam Scrap Opposite, Vellore, Timiri - 632512.</p>
            </div>
            <div className='footerContact'>
                <h3 className='serviceCardTitle footerUnderline'>Contact Info</h3>
                <h4 className='subTitle'>Call</h4>
                <p className='sectionContent'>9344504082, 04172-290218</p>
                <h4 className='subTitle'>Mail</h4>
                <p className='sectionContent'>tamilselvanrs@vrtshealthcareindia.com</p>
            </div>
            <div className='footerMenu'>
                <h3 className='serviceCardTitle footerUnderline'>Quick Links</h3>
                <ul>
                    <li><Link to={"/"} className='sectionContent'>Home</Link></li>
                    {/* <li><Link to={"/service"}>Service</Link></li> */}
                    <li><Link to={"/about"} className='sectionContent'>About Us</Link></li>
                    <li><Link to={"/contact"} className='sectionContent'>Contact Us</Link></li>
                </ul>
            </div>
            <div className='footerWrite'>
                <div className='google'>
                    <p className='sectionContent'>Write Us a Review</p>
                    <figure>
                        <a href='https://g.page/r/CX3lF39y4Pt7EAE/review'>
                            <img src='./images/google.svg' alt='google'/>
                        </a>
                    </figure>
                </div>
                <div className='social'>
                    <a href='https://www.facebook.com/vrts.healthcare'>
                        <img src='./images/FB.svg' alt='FB'/>
                    </a>
                    <a href='https://x.com/vrts_labs'>
                        <img src='./images/x.svg' alt='X'/>
                    </a>
                    <a href='https://www.instagram.com/vrts_labs/'>
                        <img src='./images/insta.svg' alt='Lin'/>
                    </a>
                    <a href='https://api.whatsapp.com/send/?phone=919344504082&text&type=phone_number&app_absent=0'>
                        <img src='./images/wa.svg' alt='WA'/>
                    </a>                                                            
                </div>
            </div>
        </section>
    )
}