import { useEffect, useState } from 'react'
import { AboutSection, Counter, EnqueryForm, ServiceCard, Testimonial, Title } from '../components/comps'
import './home.css'
import { Link } from 'react-router-dom'

export default function Home(){  
    const service = [{
        image:'./images/test/test1.svg',
        title:'CBC Test (23 Test)(Complete Blood Count)',
        btn:'Book Now',
    }, {
        image:'./images/test/test2.svg',
        title:'Diabetes HbA1c Test(2 Test)',
        btn:'Book Now',
    }, {
        image:'./images/test/test3.svg',
        title:'Thyroid Panel (Total T3, T4, TSH Serum) (3 Test)',
        btn:'Book Now',
    }, {
        image:'./images/test/test4.svg',
        title:'Vitamin D Test (Vitamin D 25 Hydroxy OH)',
        btn:'Book Now',
    }, {
        image:'./images/test/test5.svg',
        title:'Vitamin B12 Test',
        btn:'Book Now',
    }, {
        image:'./images/test/test6.svg',
        title:'LFT-Liver Function Test (Test 11)',
        btn:'Book Now',
    }, {
        image:'./images/test/test7.svg',
        title:'RFT-Renal Function Test (Test 32)',
        btn:'Book Now',
    }, {
        image:'./images/test/test8.svg',
        title:'Urine Test (21 Test)',
        btn:'Book Now',
    }, ]

    const why=[{
        title:"Advanced Vacuum Blood Collection.",
        content:"Our state-of-the-art vacuum blood collection system ensures a smooth, efficient, and minimally invasive process. This advanced technology improves accuracy and patient comfort, setting a high standard for blood sample collection.",
        flow:1
    }, {
        title:"7-Step Procedure",
        content:"We follow a meticulous 7-step procedure to guarantee comprehensive and reliable testing. Each step is carefully designed to maintain high standards of accuracy and safety throughout the diagnostic process.",
        flow:2
    }, {
        title:"Excellent Quality",
        content:"We are dedicated to delivering exceptional quality in all our services. From cutting-edge technology to rigorous quality control, our commitment to excellence ensures that you receive the best possible care and accurate results.",
        flow:3
    }, {
        title:"E-Receipt & Reports",
        content:"Enjoy the convenience of receiving your e-receipt and test reports electronically. Our digital reporting system provides quick and secure access to your results, making it easy to review and manage your health information.",
        flow:4
    }, {
        title:"Senior Citizen Discounts",
        content:"We offer special discounts for senior citizens to make health care more accessible. Our goal is to provide quality diagnostics and care at a reduced cost for those who need it most.",
        flow:5
    }, ]

    return(
        <>
            <section className='homrHero'>
                <main>
                    <Title title={"24/7 Diagnostic Service"}/>
                    {/* <h2 >Caring for <span>Health</span> <br/>Caring for You</h2> */}
                    <h2 >Precision <span>Diagnostics</span> <br/>Personalized Care.</h2>
                    {/* <p className='sectionContent heroContent'>A brief statement outlining the purpose and mission of the clinic. This can include the commitment to patient care, community health.</p> */}
                    <p className='sectionContent heroContent'>Our lab ensures accurate diagnostics tailored to your unique health needs. We use advanced technology and methods to provide results that are not only precise but also customized to fit your personal health profile.</p>
                    <aside>
                        <Link to="/contact" className='themeBtn Btn'>Book Now</Link>
                        <button className='theme2Btn Btn'>See All Service</button>
                    </aside>
                </main>   
                <figure className='homeHeroImage'>
                    <img src='./images/hero_2_1.png' alt='Hero'/>
                </figure>   
                <div className='heroFloat1'>
                    <img src='./images/hero_shape_1_1.svg' alt='+'/>
                </div>          
                <div className='heroFloat2'>
                    <img src='./images/hero_shape_1_2.svg' alt='+'/>
                </div>          
                <div className='heroFloat3'>
                    <img src='./images/hero_shape_1_3.svg' alt='+'/>
                </div>          
                <div className='heroFloat4'>
                    <img src='./images/hero_shape_1_2.svg' alt='+'/>
                </div>          
                <div className='heroFloat5'>
                    <img src='./images/hero_shape_1_4.svg' alt='+'/>
                </div>          
            </section>
            <AboutSection/>
            <section className='homeServices'>
                <header>
                    <Title
                    title={"our serivce"}/>
                    <h2 className='sectionTopic'>Our Specialty<br/>Services</h2>
                </header>
                <article>                    
                    {service.map((card, key)=>(<ServiceCard props={card} key={key}/>))}
                </article>
            </section>
            <section className='homeWelcome'>
                <main>
                    <h2>Precise testing and trustworthy health insights.</h2>
                    <p>Depend on us for precise testing and trustworthy health insights. Our lab is equipped to deliver highly accurate results, supported by a commitment to reliability in every aspect of our service.</p>
                    <aside>
                        <button className='Btn themeBtn'>Book Now</button>
                        <button className='Btn'>See all services</button>
                    </aside>
                </main>
                <figure>
                    <img src='./images/cta_1.png' alt=''/>
                </figure>
            </section>
            <section className='homeChoose'>
                <main>
                    <Title
                    title={'Why choose us'}/>
                    <h2>We Are Always Open For Your Health Services</h2>
                    <div>
                        {why.map((i, k)=>(
                            <aside>
                                <h3 className='subTitle'>{i.title}</h3>
                                <p>{i.content}</p>
                                <span>{i.flow}</span>
                            </aside>
                        ))}

                        {/* <aside>
                            <h3 className='subTitle'>Compassionate & Expert Care</h3>
                            <p>Our team of dedicated healthcare professionals combines years of experience with a genuine commitment to providing.</p>
                            <span>02</span>
                        </aside> */}
                    </div>
                </main>
                <figure>
                    <img src='./images/why_1.jpg' alt=''/>
                </figure>
            </section>
            <Counter/>
            <section className='homeFlow'>
                <Title title={"work process"}/>
                <h2>Our Home Test Sample Collection Process</h2>
                <main>
                    <div>
                        <aside>
                            <figure className='processTheme'>
                                <img  src='./images/process_card_1.jpg'  alt=''/>
                            </figure>
                            <span className='processThemeSpan'>01</span>
                        </aside>
                        <h3 className='subTitle'>Online / Call Booking</h3>
                        <p className='sectionContent processContent'>Book us Online through website or through call</p>
                    </div>
                    <div>
                        <aside>
                            <figure className='processTheme2'>
                                <img src='./images/process_card_2.jpg'  alt=''/>
                            </figure>
                            <span className='processTheme2Span'>02</span>
                        </aside>
                        <h3 className='subTitle'>Schedule</h3>
                        <p className='sectionContent processContent'>Our Team confirm your Booking for home Blood sample Collection and we will proceed to scheduling</p>
                    </div>
                    <div>
                        <aside>
                            <figure className='processTheme'>
                                <img  src='./images/process_card_3.jpg'  alt=''/>
                            </figure>
                            <span className='processThemeSpan'>03</span>
                        </aside>
                        <h3 className='subTitle'>Home Collection</h3>
                        <p className='sectionContent processContent'>We will Collect the Sample from your place at your comfortable time</p>
                    </div>
                    <div>
                        <aside>
                            <figure className='processTheme2'>
                                <img src='./images/process_card_4.jpg'  alt=''/>
                            </figure>
                            <span className='processTheme2Span'>04</span>
                        </aside>
                        <h3 className='subTitle'>Results to your Mobile</h3>
                        <p className='sectionContent processContent'>Precise and Fast resulted will be sent to your mobile at ease.</p>
                    </div>
                </main>
            </section>
            <Testimonial/>
            <EnqueryForm/>
        </>
    )
}